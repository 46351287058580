<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <v-textarea
          label="COD_ID pertence a lista"
          v-model="codId"
          rows="3"
          no-resize
          class="mb-0"
          hint="Um COD_ID por linha"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <v-textarea
          label="NOME pertence a lista"
          v-model="nome"
          rows="3"
          no-resize
          class="mb-0"
          hint="Um NOME por linha"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <conjuntos-data-table
          :month="month"
          :bdgdVersion="bdgdVersion"
          @itemsChanged="conjuntosAtualizados"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'FormConjuntoEletricoBdgdConfig',

  components: {
    ConjuntosDataTable: () =>
      import('@/components/general/data-tables/ConjuntosDataTable')
  },

  props: {
    month: {
      type: String
    },
    bdgdVersion: {
      type: Number
    },
    algoritmoConfig: {
      type: Object
    }
  },

  data() {
    return {
      numConjuntos: 0,
      codId: null,
      nome: null
    };
  },

  mounted() {
    if (this.algoritmoConfig) this.fillInputs();
  },

  watch: {
    numConjuntos() {
      this.algoritmoConfigAtualizado();
    },
    codId() {
      this.algoritmoConfigAtualizado();
    },
    nome() {
      this.algoritmoConfigAtualizado();
    }
  },

  methods: {
    conjuntosAtualizados(event) {
      this.numConjuntos = event.numItems;
    },

    algoritmoConfigAtualizado() {
      this.$emit('algoritmoConfigAtualizado', {
        numConjuntos: this.numConjuntos,
        codId: this.codId,
        nome: this.nome
      });
    },

    fillInputs() {
      this.numConjuntos = this.algoritmoConfig.numConjuntos;
      this.codId = this.algoritmoConfig.codId;
      this.nome = this.algoritmoConfig.nome;
    }
  }
};
</script>
